function adjustMainContentPadding() {
	const header = document.getElementById('site-header');
	const mainContent = document.querySelector('main');
	const headerHeight = header.offsetHeight;
	mainContent.style.paddingTop = headerHeight + 'px';
}

// Run on page load
adjustMainContentPadding();

// Run on window resize
window.addEventListener('resize', adjustMainContentPadding);