document.addEventListener("DOMContentLoaded", () => {
    const modalOpenButtons = document.querySelectorAll("button.open-modal");

    const applyButton = document.querySelector(".apply.button");

    applyButton.addEventListener("click", () => {
        const modal = document.querySelector(".form-modal");

        const modalButton = modal.querySelector("button");
        modalButton.click();


        const modalCloseBtn = document.querySelector("button.tf-v1-close");
        modalCloseBtn.innerHTML = `<img src="${themeData.themeUrl}/assets/svg/close.svg" alt="close">`;
    })

    modalOpenButtons.forEach((button) => {
        button.addEventListener("click", () => {
            const modal = document.querySelector(".form-modal");
            const modalButton = modal.querySelector("button");
            modalButton.click();
        })


    })
})
