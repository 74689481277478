import {gsap} from "gsap";

document.addEventListener('DOMContentLoaded', function () {
    const introAnimationDiv = document.querySelector("#intro-animation");
    if (!introAnimationDiv) {
        return;
    }

    if (sessionStorage.getItem('introAnimationShown')) {
        introAnimationDiv.style.display = 'none';
        document.querySelector("main").style.display = 'block';
        return;
    }

    sessionStorage.setItem('introAnimationShown', 'true');

    document.body.classList.add('no-scroll');
    const introVideo = introAnimationDiv.querySelector("video.animated-intro");
    const copyContainer = introAnimationDiv.querySelector(".copy");
    const introText = copyContainer.querySelector("h2");
    const mainContent = document.querySelector("main");

    // Split text into lines
    const lines = introText.innerHTML.split('<br>').map(line => line.trim());
    introText.innerHTML = lines.map(line => `<span class="line"><span class="line-inner">${line}</span></span>`).join('');

    const lineElements = introText.querySelectorAll('.line-inner');

    let tl = gsap.timeline({paused: true});

    introVideo.addEventListener("ended", () => {
        tl.to(introVideo, {duration: 1, autoAlpha: 0, ease: 'power2.inOut'})
            .set(copyContainer, {autoAlpha: 1})
            .from(lineElements, {
                duration: 1,
                y: "100%",
                autoAlpha: 0,
                stagger: 0.2,
                ease: 'power2.inOut'
            })
            .to({}, {duration: 2})
            .to(lineElements, {
                duration: 1,
                y: "-100%",
                autoAlpha: 0,
                stagger: 0.2,
                ease: 'power2.inOut'
            })
            .set(copyContainer, {autoAlpha: 0})
            .to(introAnimationDiv, {
                duration: 1,
                autoAlpha: 0,
                ease: 'power2.inOut',
                onStart: function () {
                    document.body.classList.remove('no-scroll');
                },
                onComplete: function () {
                    introAnimationDiv.style.display = 'none';
                    mainContent.style.display = 'block';
                    gsap.to(mainContent, {duration: 1, autoAlpha: 1, ease: 'power2.inOut'});
                }
            });
        tl.play();
    });
});