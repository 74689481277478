import Swiper from 'swiper/bundle';


let logoMarqueeSwiper = null;

function initializeSwiper() {
    if (window.innerWidth <= 768) {
        if (!logoMarqueeSwiper) { // Only initialize if not already initialized
            logoMarqueeSwiper = new Swiper(".swiper.designers-swiper", {
                slidesPerView: 'auto',
                spaceBetween: 10,
                grabCursor: true,
            });
        }
    } else {
        if (logoMarqueeSwiper) { // Destroy only if initialized
            logoMarqueeSwiper.destroy(true, true);
            logoMarqueeSwiper = null;
        }
    }
}

// Initial check on page load
document.addEventListener("DOMContentLoaded", initializeSwiper);

// Add event listener for window resize
window.addEventListener('resize', initializeSwiper);